<script>
import { mapGetters } from 'vuex'
import A4 from '../../assets/Layouts/rectangle-a4.vue'
import Badge from '../../assets/Layouts/badge-circular.vue'
import NFT from '../../assets/Layouts/rectangle-nft.vue'
// import { CardDesign } from '../../../../consts/designPresets'

import * as designPresets from '../../../../consts/designPresets'

export default {
  components: {
    A4,
    Badge,
    NFT,
  },

  data() {
    return {
      customTemplate: { 'wallid.io': true },
    }
  },
  computed: {
    ...mapGetters(['adminEmail']),
    currentLayout() {
      return this.$store.getters.currentLayout
    },
    layouts() {
      if (
        this.adminEmail
        && this.customTemplate[this.getEmail(this.adminEmail).domain]
      ) {
        return ['A4', 'Badge', 'NFT']
      }
      else {
        return ['A4', 'Badge']
      }
    },
  },
  methods: {
    changeLayout(layout) {
      this.debug('Change Layout', layout)
      this.$store.commit('changeLayout', layout)
      this.$store.dispatch('enableBackSide', false)
      this.$store.dispatch('setActiveComponent', '')
      // this.$store.commit('updateLayoutBackgroundColor', '#29969E')
      this.$store.commit('setComponents', [])
      if (layout !== 'NFT') {
        this.$store.commit('setComponents', [
          {
            id: 0,
            type: 'qrcode',
            templateSide: 'front',
            x: 94.4,
            y: 92.4,
            isDraggable: false,
          },
        ])
      }
      if (layout === 'A4') {
        this.debug(
          'Set nft components for ',
          this.$store.getters.currentLayout,
        )
        // this.$store.commit('setComponents', [...designPresets.AssociateCertificateDesign.components])
        this.$store.dispatch('setTemplate', designPresets.AssociateCertificateDesign)
      }
    },
    isCurrentLayout(layout) {
      return layout === this.currentLayout
    },
  },

}
</script>

<template>
  <div style="overflow: scroll !important; padding: 1.5rem; min-height: 40rem">
    <!-- Tab Title -->
    <div>{{ $t('templates.title') }}</div>
    <!-- Layouts -->
    <!-- ADD: Custom ratio layout -->
    <div
      v-for="layout in layouts"
      :key="layout"
      style="overflow: visible !important"
      @click="changeLayout(layout)"
    >
      <div class="layout-name">
        {{ layout }}
      </div>
      <component
        :is="layout"
        :highlight="isCurrentLayout(layout)"
        background="#ffffff"
        style="overflow: visible !important"
      />
    </div>
  </div>
</template>

<style scoped>
.layout-name {
  font-size: 12px;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
</style>
