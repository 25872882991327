<script>
import IconCheck from '../../assets/icon-check'
import IconInfo from '../../assets/icon-info'

export default {
  components: {
    IconCheck,
    IconInfo,
  },
  data() {
    return {
      displayInfoMandatory: false,
    }
  },
  computed: {
    template() {
      return this.$store.getters.template
    },
    activeComponent() {
      return this.$store.getters.activeComponent
    },
    currentSide() {
      return this.$store.getters.currentSide
    },
    components() {
      return this.$store.getters.template.components
    },
    componentIDCount() {
      return this.$store.getters.componentIDCount
    },
    attributes() {
      // this.template.components.filter((e) => {
      //     function hasDynamicText(attribute) {
      //         console.log(attribute);
      //         return attribute.type == 'text' && attribute.text.match(/\[[A-Z0-9]*\]/g) ? true : false
      //     }
      //     return hasDynamicText(e)
      // }
      function sortComponentByText(e) {
        // return component.type === 'text'
        function hasDynamicText(attribute) {
          return !!(attribute.type === 'text'
            && attribute.text.match(/\[[A-Z0-9_]*\]/g))
        }
        return hasDynamicText(e)
      }
      return this.components.filter(sortComponentByText)
    },
    hasDynamicAttributes() {
      return this.$store.getters.hasDynamicAttributes
    },
  },
  methods: {
    extractDynamicText(attribute) {
      return attribute.text.match(/\[[A-Z0-9_]*\]/g)
    },
    /* is this method necessary with the trusty and falsy values of the above method? */
    hasDynamicText(attribute) {
      return !!attribute.text.match(/\[[A-Z0-9_]*\]/g)
    },
    // hasAnyDynamicText() {
    //     this.setHasDynamicAttributes(false)
    //     for(var i = 0; i < this.attributes.length; i++){
    //         this.hasDynamicText(this.attributes[i]) ? this.setHasDynamicAttributes(true) : null
    //     }
    //     return this.hasDynamicAttributes
    // },
    setAttributes(defaultText) {
      const attribute = {
        id: this.componentIDCount,
        type: 'text',
        templateSide: this.currentSide,
        text: defaultText,
        justification: 'center',
        fontFamily: 'Montserrat',
        fontSize: 16,
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        fontColor: '#000000',
        x: 50,
        y: 50,
        isMandatory: true,
        isEditable: false,
        isDraggable: false,
        warp: 0,
        spacing: 0,
        visible: true,
      }
      this.incrementComponentIDCount()
      this.template.components.push(attribute)

      if (this.activeComponent.isEditable) {
        this.activeComponent.isEditable = false
      }

      if (this.hasDynamicText(attribute)) {
        this.setHasDynamicAttributes(true)
      }
      this.$store.dispatch('setActiveComponent', attribute)
    },
    mandatoryToggle(attribute) {
      attribute.isMandatory = !attribute.isMandatory
    },
    isRepeatedText(text) {
      const dynamicTexts = this.attributes.map(text =>
        this.extractDynamicText(text),
      )
      let count = 0
      if (dynamicTexts) {
        for (let i = 0; i < dynamicTexts.length; i++) {
          if (dynamicTexts[i]) {
            dynamicTexts[i].forEach(
              attribute => attribute === text && count++,
            )
            if (count > 1) {
              this.$store.commit('setRepeatedAttributes', true)
              return true
            }
          }
        }
        this.$store.commit('setRepeatedAttributes', false)
        return false
      }
    },
    incrementComponentIDCount() {
      this.$store.commit('incrementComponentIDCount')
    },
    setHasDynamicAttributes(setting) {
      return this.$store.dispatch('setHasDynamicAttributes', setting)
    },
  },
}
</script>

<template>
  <div class="attributes">
    <div>{{ $t('attributes.title') }}</div>
    <div />
    <li class="instructions">
      {{ $t('attributes.textLine1') }}
    </li>
    <button style="margin-bottom: 1rem" @click="setAttributes('text')">
      {{ $t('attributes.buttonAddText') }}
    </button>
    <li class="instructions">
      {{ $t('attributes.textLine2') }}
    </li>
    <div class="instructions">
      {{ $t('attributes.textLine3') }}
    </div>
    <button @click="setAttributes('[PLACEHOLDER]')">
      {{ $t('attributes.buttonAddPlaceholder') }}
    </button>

    <hr>

    <div class="instructions">
      <strong>{{ $t('attributes.added') }}</strong>
    </div>
    <div class="options">
      {{ $t('attributes.attributeName') }}
      <div style="flex-grow: 1" />
      {{ $t('attributes.mandatory') }} <span style="color: red">*</span>
      <div
        class="test"
        @mouseenter="displayInfoMandatory = true"
        @mouseleave="displayInfoMandatory = false"
      >
        <IconInfo />
        <div v-show="displayInfoMandatory" class="information">
          {{ $t('attributes.tooltip') }}
        </div>
      </div>
    </div>

    <hr style="margin: 1rem 0; background-color: #eeeeee; height: 0.05rem">

    <div
      v-for="attribute in attributes"
      :key="attribute.id"
      class="attributes-list"
    >
      <div v-if="hasDynamicText(attribute)" style="padding-top: 0">
        <div
          v-for="(dynamicText, index) in extractDynamicText(attribute)"
          :key="index"
          style="padding-top: 0; font-size: 11px"
          :class="{ repeatedText: isRepeatedText(dynamicText) }"
        >
          {{ dynamicText }}
        </div>
        <div
          class="checkbox"
          :class="{ checked: attribute.isMandatory }"
          @click="mandatoryToggle(attribute)"
        >
          <IconCheck />
        </div>
      </div>
    </div>
    <div
      v-if="attributes.length === 0"
      style="display: flex; text-align: center; color: red; font-size: 12px"
    >
      {{ $t('attributes.noDynamicAttr') }}
    </div>
  </div>
</template>

<style scoped>
button {
  margin-top: 1rem;
  padding: 0.5rem;
  color: #009fb1;
  border: 1px solid #009fb1;
  border-radius: 0.3rem;
}

hr {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  border: none;
  background-color: #d8d8d8;
  height: 0.05rem;
}

.options {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-size: 12px;
  overflow: visible !important;
}

.checkbox {
  display: flex;
  padding-top: 0.1rem;
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 0.4rem;
  border: 1px solid #d8d8d8;
  border-radius: 0.2rem;
  cursor: pointer;
}

.checked {
  background-color: #009fb1;
  border: 1px solid #009fb1;
}

.disabled {
  color: #d8d8d8;
}

.information {
  z-index: 11;
  position: absolute;
  padding: 0.5rem;
  top: -50%;
  left: 100%;
  transform: translate(-100%, -100%);
  text-align: center;
  line-height: 125%;
  font-size: 10px;
  width: 12rem;
  background-color: #eeeeee;
  border-radius: 0.3rem;
}

.test {
  display: flex;
  align-content: center;
  padding: 0;
  position: relative;
  overflow: visible !important;
  margin-left: 0.3rem;
}

.attributes {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5rem;
  font-size: 15px;
  font-weight: 500;
  min-height: 40rem;
}

.instructions {
  padding-top: 0;
  margin-top: 1.5rem;
  font-size: 12px;
}

.attributes-list > div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 0;
  padding-right: 2.5rem;
}

.attributes-list {
  padding-top: 0;
}

.repeatedText {
  color: red;
}
</style>
