<script>
import { TEMPLATE_EDITOR_CREATE } from '../../../store/actions'
import IconArrowBack from '../assets/icon-arrow-back'
import IconEdit from '../assets/icon-edit'
import MessageModal from '../modals/MessageModal.vue'

export default {
  components: {
    IconArrowBack,
    IconEdit,
    MessageModal,
  },
  data() {
    return {
      visibleWarning: false,
      showConfirmCreateModal: false,
      checkIssueConditions: false,
      message: 'confirm',
    }
  },
  computed: {
    components: {
      get() {
        return this.$store.getters.components
      },
    },
    templateName: {
      get() {
        return this.$store.getters.templateName
      },
      set(value) {
        this.$store.commit('setTemplateName', value)
      },
    },
    template() {
      return this.$store.getters.template
    },
    hasDynamicAttributes() {
      return this.$store.getters.hasDynamicAttributes
    },
    currentLayout() {
      return this.$store.getters.currentLayout
    },
  },
  methods: {
    handleClose() {
      if (this.message === 'confirm') {
        this.showConfirmCreateModal = false
      }
      else if (this.message === 'successCreateTemplate') {
        console.log('handleClose', this.tid)
        if (this.tid) {
          this.$store.commit('isPrinting', false)

          this.$router.push({
            name: `ViewTemplate`,
            params: { tid: this.tid },
          })
        }
        clearTimeout(this.timeout)
      }
    },
    checkConditions() {
      console.log('checkConditions', this.hasDynamicAttributes)
      if (!this.hasDynamicAttributes) {
        this.$store.dispatch('selectTab', 'Attributes')
      }
      if (this.issuable()) {
        this.showConfirmCreateModal = true
      }
      else {
        this.checkIssueConditions = true
      }
    },
    async toDataURL(url) {
      if (url.startsWith('data:image')) { return url }

      return await new Promise((resolve) => {
        const xhr = new XMLHttpRequest()
        xhr.onload = function () {
          const reader = new FileReader()
          reader.onloadend = function () {
            resolve(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.onerror = function () {
          resolve(undefined)
          console.error('** An error occurred during the XMLHttpRequest')
        }
        xhr.open('GET', `${url}?_=${Date.now()}`, true)
        xhr.responseType = 'blob'
        xhr.send()
      })
      // var xhr = new XMLHttpRequest();
      // xhr.onload = function () {
      //   var reader = new FileReader();
      //   reader.onloadend = function () {
      //     callback(reader.result);
      //   };
      //   reader.readAsDataURL(xhr.response);
      // };
      // xhr.open('GET', url + '?_=' + Date.now(), true);
      // xhr.responseType = 'blob';
      // xhr.send();
    },
    async createTemplate() {
      if (this.issuable()) {
        // display stopper
        // generate true QRCode
        // send store data to back end through AXIOS
        this.$store.commit('isPrinting', true)

        // Convert images to data url to avoid cors errors

        for (const e of this.components) {
          if (e.type === 'image') {
            e.srcUrl = e.src
            e.src = await this.toDataURL(e.src)
          }
        }

        for (const e of this.components) {
          if (e.type === 'image') {
            e.src = e.srcUrl
            delete e.srcUrl
          }
        }

        this.$store
          .dispatch(TEMPLATE_EDITOR_CREATE, { preview: '' })
          .then((data) => {
            console.log(data)
            this.message = 'successCreateTemplate'
            this.tid = data.tid
            this.timeout = setTimeout(() => {
              this.handleClose()
            }, 1000 * 3)
          })
          .catch((err) => {
            console.error(err)
            this.showConfirmCreateModal = false
          })
      }
    },
    displayWarning() {
      if (!this.issuable()) {
        this.visibleWarning = true
      }
    },
    issuable() {
      console.log(
        'issuable',
        this.hasDynamicAttributes,
        this.template.name,
        this.template.repeatedAttributes,
      )
      return (
        this.templateName
        && !this.template.repeatedAttributes // fix reactivity
        && this.hasDynamicAttributes // &&
        // Bypass guilherme forced invisible attributes for NFT
        // this.$store.getters.componentIDCount > 8
      )
    },
  },
}
</script>

<template>
  <div class="header">
    <div class="header-left" @click="$router.go(-1)">
      <IconArrowBack />
      <h4>{{ $t('header.title') }}</h4>
    </div>
    <input
      v-model="templateName"
      type="text"
      placeholder="Name this design"
      :class="{ warningName: checkIssueConditions }"
      style="
        min-width: 0 !important;
        width: 8.75rem !important;
        margin-right: 0.5rem;
      "
    >
    <IconEdit />
    <button
      style="position: relative; overflow: visible !important"
      :disabled="!currentLayout"
      @click="checkConditions()"
      @mouseover="displayWarning()"
      @mouseout="visibleWarning = false"
    >
      {{ $t('header.buttonCreate') }}
    </button>
    <MessageModal
      v-if="showConfirmCreateModal"
      :message="message"
      @close="handleClose()"
      @confirm="createTemplate()"
    />
  </div>
</template>

<style scoped>
.header {
  z-index: 999;
  display: flex;
  align-items: center;
  min-height: 4rem;
  max-height: 4rem;
  padding: 1.25rem 1.5rem;
  border: 1px solid #d8d8d8;
  overflow: visible !important;
}

.header-left {
  display: flex;
  align-items: center;
  min-width: 22.5rem;
  cursor: pointer;
}

h4 {
  margin-left: 1rem;
}

input {
  min-width: 16.5rem; /* needed to bypass select2 not properly setting width of the placeholder */
  font-size: 15px;
  font-weight: 500;
}

::placeholder {
  color: #b8b9bb;
  opacity: 1; /* Firefox */
  text-decoration: underline; /* not ideal, box-border bottom technique? lot of effort ???*/
}

.warningName::placeholder {
  color: red;
  opacity: 1; /* Firefox */
}

button {
  margin-left: auto;
  padding: 0.6rem 1.7rem;
  border: 1.5px solid #009fb1;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  color: white;
  background-color: #009fb1;
}

.create-warning {
  position: absolute;
  padding: 0.5rem;
  top: 50%;
  left: -1rem;
  transform: translate(-100%, -50%);
  text-align: center;
  line-height: 125%;
  font-size: 10px;
  width: 17rem;
  background-color: #eeeeee;
  border-radius: 0.3rem;
}
</style>
