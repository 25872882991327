<script>
import { mapGetters } from 'vuex'
import { UPLOAD_FILE } from '../../../../store/actions'

export default {
  methods: {
    uploadImage() {
      this.$refs.uploadImage.click()
    },
    async newImage(event) {
      // const fileReader = new FileReader();
      // const templatesComponent = this;
      // fileReader.onload = function () {
      //   templatesComponent.$store.commit('addUploadedImage', fileReader.result);

      //   event.target.value = '';
      // };

      const result = await this.$store
        .dispatch(UPLOAD_FILE, {
          file: event.target.files[0],
          folder: `${this.cid}`,
        })
        .catch((err) => {
          console.error(err)
        })

      this.$store.commit('addUploadedImage', result)

      // fileReader.readAsDataURL(event.target.files[0]);
    },
    addImage(image) {
      if (this.activeComponent.type === 'image') {
        this.activeComponent.src = image
      }
      else {
        const imageComponent = {
          id: this.componentIDCount,
          type: 'image',
          templateSide: this.currentSide,
          src: image,
          x: 50,
          y: 50,
          size: 0.4,
          isDraggable: false,
          isEditable: false,
        }
        this.incrementComponentIDCount()
        this.template.components.push(imageComponent)

        this.$store.dispatch('setActiveComponent', imageComponent)
      }
    },
    incrementComponentIDCount() {
      this.$store.commit('incrementComponentIDCount')
    },
  },
  computed: {
    ...mapGetters(['cid']),
    template() {
      return this.$store.getters.template
    },
    uploadedImages() {
      return this.$store.getters.uploadedImages
    },
    currentSide() {
      return this.$store.getters.currentSide
    },
    components() {
      return this.$store.getters.template.components
    },
    componentIDCount() {
      return this.$store.getters.componentIDCount
    },
    activeComponent() {
      return this.$store.getters.activeComponent
    },
  },
}
</script>

<template>
  <div class="images">
    <div>{{ $t('images.title') }}</div>
    <input
      ref="uploadImage"
      type="file"
      style="display: none"
      accept="image/*"
      @change="newImage"
    >
    <button @click="uploadImage">
      {{ $t('images.buttonUpload') }}
    </button>

    <hr>

    <div class="instructions">
      <strong>{{ $t('images.uploaded') }}</strong>
    </div>

    <div v-for="(image, index) in uploadedImages" :key="index">
      <img
        style="min-width: 100%; max-width: 100%; border: 1px solid #f1f1f1"
        :src="uploadedImages[index]"
        alt=""
        @click="addImage(image)"
      >
    </div>
  </div>
</template>

<style scoped>
button {
  margin-top: 1.5rem;
  padding: 0.5rem;
  color: #009fb1;
  border: 1px solid #009fb1;
  border-radius: 0.3rem;
}

hr {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  border: none;
  background-color: #d8d8d8;
  height: 0.05rem;
}

.images {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  font-size: 15px;
  font-weight: 500;
  min-height: 40rem;
}

.instructions {
  padding-top: 0;
  margin-top: 0.5rem;
  font-size: 12px;
}
</style>
