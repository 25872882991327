<script>
export default {
  computed: {
    template() {
      return this.$store.getters.template
    },
    activeComponent() {
      return this.$store.getters.activeComponent
    },
    currentSide() {
      return this.$store.getters.currentSide
    },
    components() {
      return this.$store.getters.template.components
    },
    componentIDCount() {
      return this.$store.getters.componentIDCount
    },
    uploadedSignatures() {
      return this.$store.getters.uploadedSignatures
    },
  },
  methods: {
    addSignatureField() {
      const signature = {
        id: this.componentIDCount,
        type: 'signature',
        templateSide: this.currentSide,
        text: 'Signature',
        fontSize: 16,
        fontColor: '#000000',
        fontFamily: 'Dawning of a New Day',
        x: 50,
        y: 50,
        isEditable: false,
        isDraggable: false,
      }
      this.incrementComponentIDCount()
      this.template.components.push(signature)

      if (this.activeComponent.isEditable) {
        this.activeComponent.isEditable = false
      }
      this.$store.dispatch('setActiveComponent', signature)
    },
    incrementComponentIDCount() {
      this.$store.commit('incrementComponentIDCount')
    },
    uploadImage() {
      this.$refs.uploadImage.click()
    },
    newImage(event) {
      const fileReader = new FileReader()
      const templatesComponent = this
      fileReader.onload = function () {
        templatesComponent.$store.commit(
          'addUploadedSignature',
          fileReader.result,
        )

        event.target.value = ''
      }
      fileReader.readAsDataURL(event.target.files[0])
    },
    addImage(image) {
      if (
        this.activeComponent.type === 'signature'
        && this.activeComponent.src
      ) {
        this.activeComponent.src = image
      }
      else {
        const imageComponent = {
          id: this.componentIDCount,
          type: 'signature',
          templateSide: this.currentSide,
          src: image,
          x: 50,
          y: 50,
          size: 0.3,
          isDraggable: false,
          isEditable: false,
        }
        this.incrementComponentIDCount()
        this.template.components.push(imageComponent)

        this.$store.dispatch('setActiveComponent', imageComponent)
      }
    },
  },
}
</script>

<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      padding-top: 0;
      min-height: 40rem;
    "
  >
    <div class="signatures">
      <div>{{ $t('signatures.title') }}</div>
      <div />
      <button @click="addSignatureField">
        {{ $t('signatures.buttonAddSignature') }}
      </button>

      <hr>
      <div style="padding-top: 0">
        {{ $t('signatures.titleImage') }}
      </div>
      <input
        ref="uploadImage"
        type="file"
        style="display: none"
        accept="image/*"
        @change="newImage"
      >
      <button @click="uploadImage">
        {{ $t('signatures.buttonUpload') }}
      </button>

      <div />
      <div class="instructions">
        <strong>{{ $t('signatures.uploaded') }}</strong>
      </div>

      <div v-for="(image, index) in uploadedSignatures" :key="index">
        <img
          style="min-width: 100%; max-width: 100%"
          :src="uploadedSignatures[index]"
          alt=""
          @click="addImage(image)"
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Covered+By+Your+Grace&family=Dawning+of+a+New+Day&family=Nothing+You+Could+Do&display=swap');

button {
  margin-top: 1.5rem;
  padding: 0.5rem;
  color: #009fb1;
  border: 1px solid #009fb1;
  border-radius: 0.3rem;
}

hr {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  border: none;
  background-color: #d8d8d8;
  height: 0.05rem;
}

.signatures {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  font-size: 15px;
  font-weight: 500;
}

.instructions {
  padding-top: 0;
  margin-top: 0.5rem;
  font-size: 12px;
}

.selected {
  border-bottom: 3px solid #009fb1;
}

.signature-1 {
  font-family: 'Dawning of a New Day';
}
</style>
